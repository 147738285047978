import * as React from "react";
import FooterComponent from "../footer/footer";
import NavbarComponent from "../navbar/navbar";
import PaymentForm from "../donation/payment-form";
import PaymentGateWayJson from '../../json/payment-app-type.json';
import { Step, StepLabel, Stepper } from "@mui/material";

const SponsorAFamily = () => {
    // const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024 && window.innerWidth > 880);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 576 && window.innerWidth < 992);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth < 576);
    const paymentUrl = `${PaymentGateWayJson.BaseUrl}${PaymentGateWayJson.PaymentAppName1}`;
    const myRef = React.useRef<HTMLDivElement>(null);

    const steps = [
        'You sponsor a family',
        'We establish a link between our partner hotel and the treating hospital.',
        'A patient family in need receives a room at one of our partner hotels.',
    ];

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 576 && window.innerWidth < 992;
            const islessmobile = window.innerWidth < 576;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            } else {
                setIsLessMobile(islessmobile)
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
    }, [isTablet, isLessMobile])
    return (
        <div className="main-body">
            <NavbarComponent />
            <div className="image-hero image-hero-qJGki4uNa8MqtyrG">
                <>
                    {
                        isTablet || isLessMobile ? (
                            <div className="sponsorMainDiv marginAuto">
                                <div className="marginTop50">
                                    <p className="p40 boxColorYellow fontFamilyTimesNewRoman text-center">
                                        <span className="spanSponsor46Px">Sponsor a Family</span>
                                    </p>
                                    <div className="row donationBox">
                                        <div className="columns large-6">
                                            <div ref={myRef} id="myRef donationView" >
                                                <div className="paymentForm">
                                                    <PaymentForm paymentUrl={paymentUrl} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="TaxDiv">
                                        <span className="backgroundColorTransparent defaultOtherFormator taxDedutionSponser borderNone textAlignCenter boxColorYellow justifyText">
                                            Tax ID# 87-2763667
                                        </span>
                                    </div>
                            </div>
                        ) : (
                            <div className="sponsorMainDiv">
                                <div className="marginTop50">
                                    <p className="p40 SponserP boxColorYellow justifyText fontFamilyTimesNewRoman pSponsor">
                                        <span className="spanSponsor paddingLeft20">Sponsor a Family</span>
                                    </p>
                                    <div className="row donationBox">
                                        <div className="columns large-6">
                                            <div ref={myRef} id="myRef donationView" >
                                                <div className="paymentForm marginLeft50">
                                                    <PaymentForm paymentUrl={paymentUrl} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="TaxDiv">
                                        <span className="backgroundColorTransparent defaultOtherFormator taxDedutionSponser borderNone textAlignCenter boxColorYellow justifyText">
                                            Tax ID# 87-2763667
                                        </span>
                                    </div>
                            </div>
                        )
                    }
                </>
            </div>
            {
                isTablet || isLessMobile ? (
                    <section className='section'>
                        <div className="bg-cw-wps-blue py-5 pb-2" id="how-sponsorship-works">
                            <div className="mx-2 md-mx-auto text-center defaultColor max-w-mdx ">
                                <h2 className="h35 font-heading mx-auto font-normal">How Sponsoring a Family&nbsp;Works:</h2>
                            </div>
                            <div className="row-lg mx-2 md-mx-auto lg-flex defaultColor text-center mt-3 items-start lg-px-5">
                                <Stepper activeStep={-1} className="defaultColor labelText" nonLinear alternativeLabel>
                                    {steps.map((label) => (
                                        <Step active={false} className="defaultColor labelText" key={label}>
                                            <StepLabel id="labelText" className="defaultColor labelText">{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </div>
                    </section>
                ) : (
                    <section className='section'>
                        <div className="bg-cw-wps-blue py-5 pb-2" id="how-sponsorship-works">
                            <div className="mx-2 md-mx-auto text-center defaultColor max-w-mdx ">
                                <h2 className="h35 font-heading mx-auto font-normal">How Sponsoring a Family&nbsp;Works:</h2>
                            </div>
                            <div className="row-lg mx-2 md-mx-auto lg-flex defaultColor text-center mt-3 items-start lg-px-5">
                                <Stepper activeStep={-1} className="defaultColor" nonLinear alternativeLabel>
                                    {steps.map((label) => (
                                        <Step className="defaultColor" key={label}>
                                            <StepLabel className="defaultColor">{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </div>
                    </section>
                )
            }
            <FooterComponent />
        </div>
    )
}
export default SponsorAFamily;